import "./App.css";
import { Route, Routes } from "react-router-dom"
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import CarbonEmissionPage from "./pages/CarbonEmissionPage";
import EnergyManagementPage from "./pages/EnergyManagementPage";
import IdentificationMethodPage from "./pages/IdentificationMethodPage";
// import EnergyManagementPage from "./pages/PowerManagementDashboard";
import ScaleDefinitionPage from "./pages/ScaleDefinitionPage";
import StrategyFormPage from "./pages/StrategyFormPage";
import SideDrawer from "./components/Sidebar";
import Header from "./components/Header";

let theme = createTheme({
  palette: {
    primary: {
      main: "#00b388",
      light: "#e2f1ee",
      dark: "#00B388",
      contrastText: "#000000",
    },
  },
});

declare module "@mui/material/styles" {
  interface Theme {
    palette: {
      primary: {
        main: string;
        light: string;
        dark: string;
        contrastText: string;
      };
    };
  }

  // interface ThemeOptions {
  //   palette?: {
  //     primary?: {
  //       main?: string;
  //       light?: string;
  //       dark?: string;
  //       contrastText?: string;
  //     };
  //   };
  // }
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          maxWidth: "100vw",
          overflowX: "hidden",
        }}
      >
        <Grid container xs={12} direction={"row"}>
          <Header />
        </Grid>
        <Grid container xs={12} direction={"row"}>
          <Grid item xs={2} >
            <SideDrawer />
          </Grid>
          <Grid item xs={10} sx={{backgroundColor: theme.palette.primary.light}}>
            <Routes>
              <Route path="/carbon-emission" element={<CarbonEmissionPage />} />
              {/* <Route path="/energy-record" element={<EnergyRecordPage />} /> */}
              <Route path="/identification-method" element={<IdentificationMethodPage />} />
              <Route path="/energy-management" element={<EnergyManagementPage />} />
              <Route path="/scale-definition" element={<ScaleDefinitionPage />} />
              <Route path="/strategy-form" element={<StrategyFormPage />} />
            </Routes>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default App;
