import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import logo from "../assets/images/logo.png";

const Header = () => {
  return (
    <header>
      <Box>
        <Grid container xs={12} direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{width:"100vw"}}>
          <Grid item sx={{p:"30px"}}>
            <div style={{ width: "260px", height: "53px" }}>
              <img alt={"logo"} src={logo} />
            </div>
          </Grid>
          <Grid item sx={{p:"30px"}}>
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                width: "96px",
                height: "41px",
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #00B388",
                borderRadius: "15px",
              }}
            >
              登出
            </Button>
          </Grid>
        </Grid>
      </Box>
    </header>
  );
};

export default Header;
