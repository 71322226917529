import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Link from "@mui/material/Link";
import { NavLink as RouterLink } from "react-router-dom";

export default function SideDrawer() {
  const [openKey, setOpenKey] = React.useState("");
  const list = [
    {
      name: "基本鑑別資料",
      key: "basic_identification",
      subOptions: [
        {
          name: "鑑別方法",
          key: "identification_method",
          url: "/identification-method",
        },
        {
          name: "量表定義",
          key: "scale_definition",
          url: "/scale-definition",
        },
        {
          name: "策略表格",
          key: "strategy_form",
          url: "/strategy-form",
        },
      ],
    },
    {
      name: "碳足跡盤查",
      key: "carbon_footprint",
      subOptions: [
        {
          name: "能源類",
          key: "energy",
          url: "/energy",
        },
        {
          name: "交通類",
          key: "transportation",
          url: "/transportation",
        },
        {
          name: "人員類",
          key: "employee",
          url: "/employee",
        },
      ],
    },
    {
      name: "即時儀表板",
      key: "real_time_dashboard",
      subOptions: [
        {
          name: "能源管理",
          key: "energy_management",
          url: "/energy-management",
        },
        {
          name: "機房碳排放",
          key: "carbon_emission",
          url: "/carbon-emission",
        },
      ],
    },
  ];

  const handleToggleMenu = (key: string) => {
    setOpenKey(key === openKey ? "" : key);
  }

  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #016665 0%, #0AA5A3 100%)",
        boxShadow: "1px 0px 0px #00B388",
        minHeight: "100vh",
        height: "100%",
        px: "20px",
        py: "40px",
      }}
    >
      <List component="nav" sx={{ color: "white" }}>
        {list.map((primary_menu) => {
          return (
            <>
              <ListItem
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderLeft: "4px solid #ffff",
                  my: "8px",
                }}
                key={primary_menu.key}
                disablePadding
              >
                <ListItemButton onClick={()=>handleToggleMenu(primary_menu.key)}>
                  <ListItemText primary={primary_menu.name} />
                  {primary_menu.key === openKey ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
              </ListItem>
              <Collapse in={primary_menu.key === openKey} timeout="auto" unmountOnExit>
                <List
                  sx={{
                    ".active": {
                      background:
                        "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #FBBB0A",
                      borderRadius: "8px",
                      p: "8px 16px",
                      transform: "translateX(-16px)",
                      color: "#000000",
                      fontWeight: "bold",
                    },
                    
                  }}
                  component="div"
                  disablePadding
                >
                  {primary_menu.subOptions.map((sub_menu) => (
                    <ListItem key={sub_menu.key} sx={{ pl: 4 }}>
                      <Link
                        component={RouterLink}
                        to={sub_menu.url}
                        sx={{
                          fontStyle: "normal",
                          fontWeight: "normal",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                          textDecoration: "none",
                        }}
                      >
                        {sub_menu.name}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </>
          );
        })}
      </List>
    </Box>
  );
}
